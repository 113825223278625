import React from "react";

export default function Loader() {
  return (
    <section className="flex items-center justify-center p-8">
      {/* <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div className="w-28 h-28 border-8 text-purple-400 text-4xl animate-spin border-sky-300 flex items-center justify-center border-t-amber-400 rounded-full"></div>
      </div> */}
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="relative text-transparent animate-loading w-[13.6px] h-8 bg-blue-600 m-auto before:content-[''] before:absolute before:top-0 before:left-[-20px] before:w-[13.6px] before:h-8 before:bg-blue-600 before:animate-loading delay-160 after:content-[''] after:absolute after:top-0 after:left-[20px] after:w-[13.6px] after:h-8 after:bg-blue-600 after:animate-loading delay-320"></div>
      </div>
    </section>
  );
}
