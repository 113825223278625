import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    <div className="relative rounded-lg overflow-hidden shadow-2xl transition-transform transform hover:scale-105 hover:shadow-2xl group border-8 border-white">
      <Link to={`/single?slug=${data.slug}`} className="">
        <img
          src={data.logo}
          alt={data.title}
          className="w-full h-64 object-cover"
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center p-4 bg-green-500 bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <h2 className="text-xl font-bold text-white my-2">{data.title}</h2>
        </div>
      </Link>
    </div>
  );
}
