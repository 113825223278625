import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 text-black rounded-lg shadow-md m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>floraoasis.site!</b>
        </p>
        <p className="my-3">
          At floraoasis.site, we are passionate about bringing the beauty of
          nature into your home and garden. Our mission is to provide
          high-quality plants, gardening tools, and resources to help you create
          your own oasis.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in 2024, floraoasis.site was born from a love of gardening
          and a desire to share that passion with others. We believe that
          everyone should have the opportunity to experience the joy of
          nurturing and growing plants.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team is composed of experienced horticulturists, gardeners, and
          plant enthusiasts. We work together to curate a diverse selection of
          plants and provide expert advice to our customers.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Plant Selection:</b> A wide variety of plants to suit all tastes
            and environments.
          </li>
          <li className="my-2">
            <b>Gardening Tools:</b> High-quality tools and supplies to help you
            care for your plants.
          </li>
          <li className="my-2">
            <b>Expert Advice:</b> Resources and tips from our team of experts to
            support your gardening journey.
          </li>
        </ul>

        <p className="my-2">
          Thank you for choosing floraoasis.site as your gardening partner. We
          are excited to help you cultivate your own green space.
        </p>
      </section>
    </>
  );
}
