import React, { useState } from "react";
import GamesData from "../Games.json";
import { useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import AllGames from "./AllGames";
import Loader from "./Loader";

export default function Single() {
  const [searchParams] = useSearchParams();
  const [isLoad, setIsLoad] = useState(true);
  const slug = searchParams.get("slug");
  const game = GamesData.find((game) => game.slug === slug);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  setTimeout(() => {
    setIsLoad(false);
  }, 1000);

  return isLoad ? (
    <Loader />
  ) : (
    <section className="mx-3 z-50">
      <h1 className="font-extrabold text-black text-center text-3xl capitalize my-5">
        Title : {game?.title}
      </h1>
      <div className="w-full rounded-lg h-[650px] block shadow border-0 overflow-hidden my-5">
        <iframe
          src={game?.gameurl}
          className="w-full h-full overflow-hidden z-50"
          title={game?.title}
        ></iframe>
      </div>
      {game.description && (
        <div
          className="p-6 text-black shadow-sm rounded-lg text-lg my-3"
          dangerouslySetInnerHTML={createMarkup(game.description)}
        ></div>
      )}
      {game.sec_desc && (
        <div
          className="p-6 text-black shadow-sm rounded-lg text-lg my-3"
          dangerouslySetInnerHTML={createMarkup(game.sec_desc)}
        ></div>
      )}
      <AllGames />
    </section>
  );
}
