import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="p-2 bg-yellow-500 text-center text-md">
      <ul className="flex flex-col gap-8 md:gap-0 justify-center p-4 md:p-0 font-medium rounded-lg md:space-x-8 md:flex-row md:border-0 border-0 mx-auto my-5 text-sm">
        <li>
          <Link
            to="/"
            className={`border-2 border-orange-400 bg-yellow-100 hover:bg-yellow-300 focus:ring-0 focus:outline-none focus:ring-yellow-300 font-bold rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2`}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={`border-2 border-orange-400 bg-yellow-100 hover:bg-yellow-300 focus:ring-0 focus:outline-none focus:ring-yellow-300 font-bold rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 `}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/privacy-policy"
            className={`border-2 border-orange-400 bg-yellow-100 hover:bg-yellow-300 focus:ring-0 focus:outline-none focus:ring-yellow-300 font-bold rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2`}
          >
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link
            to="/term-condition"
            className={`border-2 border-orange-400 bg-yellow-100 hover:bg-yellow-300 focus:ring-0 focus:outline-none focus:ring-yellow-300 font-bold rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2`}
          >
            Term Condition
          </Link>
        </li>
        <li>
          <Link
            to="/contact-us"
            className={`border-2 border-orange-400 bg-yellow-100 hover:bg-yellow-300 focus:ring-0 focus:outline-none focus:ring-yellow-300 font-bold rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2`}
          >
            Contact Us
          </Link>
        </li>
      </ul>
      <hr className="mt-6 mb-4" />
      <p className="my-1">
        &copy; {currentYear}
        <span>
          <Link to="/" className="hover:underline ms-2">
            floraoasis.site
          </Link>
        </span>
      </p>
    </footer>
  );
}
