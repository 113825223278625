import React, { useState } from "react";
import AllGames from "./AllGames";
import Loader from "./Loader";

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <AllGames />
    </>
  );
}
